<template>
  <v-card elevation="0" outlined :style="cardStyle">
    <v-row>
      <v-col cols="12">
        <v-btn
          fab
          absolute
          top
          small
          style="left: 60px"
          color="pink"
          class="mr-1 white--text"
          :loading="cardStyle.borderColor == 'red'"
          @click="removeField"
          v-if="canDeleteField"
        >
          <v-icon color="#fff">mdi-delete</v-icon>
        </v-btn>

        <MultiEditorLanguages
          v-model="field"
          :selectedLanguage="selectedLanguage"
          :render="render"
        />
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { validationMixin } from 'vuelidate';
import { mapActions } from 'vuex';
import MultiEditorLanguages from './MultiEditorLanguages.vue';

export default {
  props: ['data', 'selectedLanguage', 'hideDeleteField', 'render'],
  components: {
    MultiEditorLanguages,
  },
  mixins: [validationMixin],
  validations: {},
  data() {
    return {
      field: {
        title: {}, // multi languages { ar: value, en: value }
        content: {}, // multi languages { ar: value, en: value }
        src: '',
      },
      cardStyle: {
        border: '2px solid',
        borderColor: 'var(--primary-color)',
        padding: '20px 10px 0',
      },
    };
  },
  computed: {
    language() {},
    canDeleteField() {
      if (this.hideDeleteField == true) return false;
      return true;
    },
  },

  methods: {
    ...mapActions('confirm', ['openConfirm']),
    removeField() {
      this.openConfirm({
        message: 'هل تريد حذف هذا الحقل؟',
        confirm: () => {
          this.cardStyle.borderColor = 'red';
          return setTimeout(() => this.$emit('remove', this.data.id), 600);
        },
      });
    },
    initField() {
      if (this.data) this.field = this.data;
    },
    changeCardBorderColor() {
      const isDark = this.$store.state.theme.dark;

      let bcolor = 'var(--primary-color)';
      if (this.render && isDark) bcolor = '#555';
      else if (this.render) bcolor = '#aaaccc';

      this.cardStyle.borderColor = bcolor;
    },
  },
  created() {
    this.initField();
  },
  mounted() {
    this.changeCardBorderColor();
  },
};
</script>

<style lang="scss" scoped></style>
