var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"base-editor",class:['base-editor', _vm.darkClass],style:({ '--ck-editor__editable__min-heigth': _vm.contentMinHeight })},[_c('div',{staticClass:"document-editor__toolbar",attrs:{"id":_vm.id}},[_c('UploadImage',{attrs:{"text":".","multiple":false,"onSelectCloseDialog":true,"btnProps":{
        hideText: true,
        absolute: true,
        fab: true,
        bottom: true,
        left: true,
        small: true,
      }},model:{value:(_vm.imageSrc),callback:function ($$v) {_vm.imageSrc=$$v},expression:"imageSrc"}})],1),_c('div',{staticClass:"document-editor__editable-container pa-4"},[_c('ckeditor',{attrs:{"editor":_vm.editor,"config":_vm.editorConfig},on:{"ready":_vm.onReady,"input":_vm.onChange},model:{value:(_vm.ckEditorData),callback:function ($$v) {_vm.ckEditorData=$$v},expression:"ckEditorData"}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }