var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',{staticClass:"fill-height"},[_c('v-col',{staticClass:"fill-height",staticStyle:{"position":"relative"}},[_c('v-row',{staticClass:"mx-2 align-center",staticStyle:{"position":"absolute","z-index":"100","height":"100%"}},[_c('v-col',{staticClass:"ma-0",attrs:{"cols":"12"}},[_c('v-row',{staticClass:"flex-column"},[_vm._l((_vm.aspectRatios),function(item,index){return _c('v-col',{key:index,attrs:{"cols":"1"}},[_c('v-btn',{attrs:{"fab":"","dark":"","color":_vm.aspectRatio == item.value ? 'primary lighten-2' : 'grey'},on:{"click":function($event){return _vm.changeAspectRatio(item.value)}}},[(item.label.startsWith('mdi'))?_c('v-icon',[_vm._v(" "+_vm._s(item.label)+" ")]):[_vm._v(" "+_vm._s(item.label)+" ")]],2)],1)}),_c('v-col',{attrs:{"cols":"1"}},[_c('v-btn',{attrs:{"fab":"","dark":"","color":"green darken-2"},on:{"click":_vm.postCroppedImage}},[_c('v-icon',[_vm._v("mdi-content-save-check")])],1)],1)],2)],1)],1),_c('v-row',{staticClass:"fill-height"},[_c('v-col',{staticClass:"fill-height ma-0 py-0",attrs:{"cols":"12"}},[_c('cropper',{staticClass:"cropper",attrs:{"src":_vm.src,"stencil-props":{
            aspectRatio: _vm.aspectRatio,
          },"canvas":{
            minHeight: 0,
            minWidth: 0,
            maxHeight: 2048,
            maxWidth: 2048,
            height: 1500,
            width: 1800,
          }},on:{"change":_vm.change}})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }