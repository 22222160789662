<template>
  <div :style="fileStyle">
    <v-btn
      fab
      absolute
      top
      left
      small
      color="pink"
      class="mr-1 white--text"
      :loading="fileStyle.borderColor == 'red'"
      @click="removeSlider(id)"
      v-if="canDeleteField"
    >
      <v-icon color="#fff">mdi-delete</v-icon>
    </v-btn>
    <div class="my-5 px-5">
      <h2>فايلات</h2>
    </div>

    <v-row>
      <v-col cols="12">
        <hr style="width: 120px" />
      </v-col>

      <v-col cols="12">
        <FileLanguages
          v-model="field"
          :selectedLanguage="selectedLanguage"
          :render="render"
        />
      </v-col>

      <v-col cols="12" v-if="!render">
        <v-text-field v-model="field.slug" label="slug" outlined />
      </v-col>
    </v-row>

    <v-row v-for="file in fileCards" :key="file.data.id">
      <v-col cols="12">
        <component
          :is="file.data.component"
          :data="file.data"
          :ref="file.data.id"
          @remove="removeFileCard"
          :selectedLanguage="selectedLanguage"
          :hideDeleteField="!canDeleteFileCard"
          :render="render"
        />
      </v-col>
    </v-row>

    <v-row v-if="canAddFileCard && render">
      <v-col>
        <v-btn
          color="primary"
          @click="addFileCard"
          :loading="loadingAdd"
          :disabled="loading"
        >
          <v-icon dark> mdi-plus </v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { makeid } from '@/utls/jsCommon';
import FileCard from './FileCard.vue';
import FileLanguages from './FileLanguages.vue';

export default {
  props: {
    selectedLanguage: {},
    hideDeleteField: {},
    data: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    render: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    FileCard,
    FileLanguages,
  },
  data() {
    return {
      imageIds: 0,
      fileCards: [],
      loadingAdd: false,
      fileStyle: {
        border: '2px solid',
        borderColor: 'var(--secondary-color)',
        borderRadius: '4px',
        padding: '10px 20px 30px',
        marginBottom: '10px',
        width: '100%',
        position: 'relative',
      },
      field: {
        slug: '',
        name: {},
        files: [
          {
            order: 1,
            ar: {
              src: '',
              desc: {
                // multi languages { ar: value, en: value }
              },
            },
            // multi languages { ar: value, en: value }
          },
        ],
      },
    };
  },
  computed: {
    id() {
      const { id } = this.data;
      return id;
    },
    canDeleteField() {
      if (this.hideDeleteField == true) return false;
      return true;
    },
    canAddFileCard() {
      return true;
    },
    canDeleteFileCard() {
      return true;
    },
  },
  methods: {
    getDetails() {
      return Object.keys(this.$refs).map((fieldRefId) => {
        const fieldRef = this.$refs[fieldRefId];
        if (!fieldRef || !fieldRef.length) return {};
        const refDtls = fieldRef[0];
        return { data: refDtls._data, $v: refDtls.$v };
      });
    },
    removeSlider(id) {
      this.fileStyle.borderColor = 'red';
      setTimeout(() => this.$emit('remove', id), 600);
    },
    removeFileCard(id) {
      const index = this.fileCards.findIndex((f) => f.data.id === id);
      index != -1 && this.fileCards.splice(index, 1);
    },
    withLoading(cb) {
      this.loadingAdd = true;
      setTimeout(() => {
        this.loadingAdd = false;
        cb();
      }, 500);
    },
    addFileCard(loading = true) {
      const add = () => {
        const data = {
          id: this.generatedId(),
          order: this.generatedOrder(),
          component: 'FileCard',
        };
        this.fileCards.push({ data });
      };
      if (loading) this.withLoading(add);
      else add();
    },
    generatedId() {
      return ++this.imageIds;
    },
    generatedOrder() {
      const files = this.fileCards || [];
      if (files.length == 0) return 1;
      const nextOrder = Math.max(...files.map((o) => o.data.order)) + 1;
      return nextOrder;
    },
    initFileCard({ empty, files }) {
      if (empty) this.addFileCard(false);
      if (!files || files.length == 0) return;
      this.fileCards = files.map((file) => ({ data: { ...file } }));
      const maxId = Math.max(...files.map((f) => f.id));
      this.imageIds = maxId;
    },
    initField() {
      if (this.data) this.field = this.data;

      const { files } = this.data || {};
      if (files) this.initFileCard({ files });
      // else this.initFileCard({ empty: true });

      if (!this.field.slug) this.field.slug = makeid(15);
    },
    changeCardBorderColor() {
      const isDark = this.$store.state.theme.dark;

      let bcolor = 'var(--primary-color)';
      if (this.render && isDark) bcolor = '#333';
      else if (this.render && !isDark) bcolor = '#eee';

      this.fileStyle.borderColor = bcolor;
    },
  },
  created() {
    this.initField();
  },
  mounted() {
    this.changeCardBorderColor();
  },
};
</script>

<style lang="scss" scoped></style>
