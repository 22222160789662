var render = function render(){var _vm=this,_c=_vm._self._c;return _c('UploadManager',{attrs:{"selectedLajnguage":_vm.selectedLajnguage,"buttonTitle":_vm.buttonTitle,"buttonIcon":_vm.buttonIcon,"multiple":_vm.multiple,"uploadNotes":"upload image from upload image manager","uploadType":"image","accept":"image/png, image/jpeg, image/bmp","browseFileIcon":"mdi-camera","title":"الصور","gridTitle":"اختر الصور","uploadTitle":"تحميل صورة","selectFileToUploadMsg":"اختر الصورة المراد تحميلها","successUploadMsg":"تم تحميل الصورة","fileNameLabel":"اسم الصورة","onSelectCloseDialog":_vm.onSelectCloseDialog,"btnProps":_vm.btnProps},scopedSlots:_vm._u([{key:"filesGridPreview",fn:function({
      files,
      selectFile,
      getSelectedFileClass,
      isSelected,
      remove,
      isLoading: fileLoading,
      editFile,
    }){return _vm._l((files),function({ src: fileSrc, alt, id: fileId, extension, creator },index){return _c('v-col',{key:index,staticClass:"d-flex child-flex",attrs:{"cols":"6","xs":"6","sm":"4","md":"3","lg":"2","xl":"2"}},[_c('v-card',{staticClass:"d-flex flex-column",class:`${getSelectedFileClass(fileSrc)}`,attrs:{"outlined":"","elevation":"0"},on:{"click":function($event){return selectFile(fileSrc)}}},[_c('v-card-text',{staticClass:"image-conainer pa-0"},[_c('v-card-actions',{staticClass:"centerFloat"},[_c('v-btn',{staticClass:"btnHide error darken-2",attrs:{"small":"","disabled":fileLoading},on:{"click":function($event){$event.stopPropagation();return remove(fileId)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1),_c('v-btn',{staticClass:"btnHide",attrs:{"small":"","disabled":fileLoading},on:{"click":function($event){$event.stopPropagation();editFile(fileId, alt, _vm.getSrc(fileSrc, 'lg'), extension)}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1)],1),_c('v-img',{attrs:{"src":_vm.getSrc(fileSrc, 'md'),"lazy-src":`https://picsum.photos/10/6?image=${index * 5}`,"contain":"","aspect-ratio":"1"},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-row',{staticClass:"fill-height ma-0",attrs:{"align":"center","justify":"center"}})]},proxy:true}],null,true)})],1),_c('div',{staticClass:"d-flex justify-space-between flex-column flex-grow-1"},[_c('v-card-title',{staticClass:"subtitle-1 pb-1"},[_vm._v(" "+_vm._s(alt)+" ")]),_c('v-card-subtitle',{staticClass:"subtitle-1 pb-1 pt-0 ma-0"},[_c('v-chip',{attrs:{"x-small":""}},[_c('v-icon',{staticClass:"ml-1",attrs:{"x-small":""}},[_vm._v("mdi-account")]),_vm._v(" "+_vm._s(_vm.getUserName(creator))+" ")],1)],1)],1)],1)],1)})}},{key:"uploadfilePreview",fn:function({
      browseFile,
      file,
      fileSrc,
      changeUploadFile,
      uploadFile,
      fileId,
      getFileFromUrl,
      isLoadingFile,
      taggleEdit,
    }){return [_c('v-fade-transition',{attrs:{"mode":"out-in"}},[_c('div',{staticClass:"file-container"},[(isLoadingFile)?_c('v-container',{staticClass:"d-flex justify-center align-center fill-height",attrs:{"fluid":""}},[_c('v-progress-circular',{attrs:{"size":100,"width":7,"color":"purple","indeterminate":""}})],1):_c('v-container',{attrs:{"fluid":""}},[(fileSrc)?_c('v-btn',{staticClass:"ma-2",staticStyle:{"top":"10px","left":"10px"},attrs:{"disabled":_vm.isEditImage,"fab":"","dark":"","absolute":""},on:{"click":() => {
                changeUploadFile(null), _vm.toggleEditImage(false);
              }}},[_c('v-icon',[_vm._v("mdi-close")])],1):_vm._e(),(fileSrc)?_c('v-btn',{staticClass:"ma-2",class:_vm.isEditImage ? 'red' : '',staticStyle:{"top":"70px","left":"10px"},attrs:{"fab":"","dark":"","absolute":""},on:{"click":() => {
                _vm.toggleEditImage();
                getFileFromUrl();
                taggleEdit();
              }}},[(_vm.isEditImage)?_c('v-icon',[_vm._v("mdi-cancel")]):_c('v-icon',[_vm._v("mdi-crop")])],1):_vm._e(),(fileSrc)?_c('v-btn',{staticClass:"ma-2",staticStyle:{"top":"130px","left":"10px"},attrs:{"disabled":_vm.isEditImage,"fab":"","dark":"","absolute":""},on:{"click":uploadFile}},[(fileId)?_c('v-icon',[_vm._v("mdi-content-save")]):_vm._e(),(!fileId)?_c('v-icon',[_vm._v("mdi-upload")]):_vm._e()],1):_vm._e(),(fileSrc && !_vm.isEditImage)?_c('v-row',{staticClass:"fill-height pa-0 ma-0"},[_c('v-col',{staticClass:"fill-height d-flex justify-center"},[_c('img',{staticStyle:{"margin":"auto","height":"100%","max-width":"100%"},attrs:{"src":fileSrc}})])],1):(fileSrc && _vm.isEditImage)?_c('image-cropper',{ref:"ImageCropper",attrs:{"file":file,"fileSrc":fileSrc},on:{"post":(file) => {
                changeUploadFile(file);
                _vm.toggleEditImage();
                taggleEdit();
              }}}):_c('v-row',{staticClass:"grey fill-height ma-0",attrs:{"align":"center","justify":"center"},on:{"click":browseFile}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-image")])],1)],1)],1)])]}},{key:"selectedFiles",fn:function({ files, selectFile }){return [_c('v-subheader',[_vm._v("الصور المختارة")]),_c('v-sheet',{staticClass:"mx-auto",attrs:{"height":"120px"}},[_c('v-slide-group',{attrs:{"multiple":"","show-arrows":"","center-active":true}},_vm._l((files),function(fileSrc,index){return _c('v-slide-item',{key:index,scopedSlots:_vm._u([{key:"default",fn:function({ active, toggle }){return [_c('div',{staticClass:"selected-file-container"},[_c('div',{staticClass:"close d-flex justify-center",on:{"click":function($event){return selectFile(fileSrc)}}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-close")])],1),_c('img',{staticClass:"ma-2",attrs:{"src":_vm.getSrc(fileSrc, 'sm'),"input-value":active}})])]}}],null,true)})}),1)],1)]}}]),model:{value:(_vm.imageModel),callback:function ($$v) {_vm.imageModel=$$v},expression:"imageModel"}})
}
var staticRenderFns = []

export { render, staticRenderFns }