import config from '@/config/config';
import { doLogout } from '@/libs/auth/authHelper';
import { showSnackbarWarningMessage } from '@/utls/snackbar';

const { information, networkConfig } = config;

const getPaths = () => {
  try {
    const baseURL = networkConfig.baseURL;
    const { uploadDirectory } = information();
    const fullUploadURL = baseURL + uploadDirectory.basePath;
    return { fullUploadURL, uploadDirectory };
  } catch (error) {
    showSnackbarWarningMessage('يرجى اعادة تسجيل الدخول');
    doLogout();
    return {};
  }
};

export const getImageURL = (name, size) => {
  try {
    if (!name) return '';
    const { fullUploadURL, uploadDirectory } = getPaths();
    let imagePath = fullUploadURL + uploadDirectory.paths.images + '/size';
    if (size) imagePath = imagePath.replace('size', size);
    else imagePath = imagePath.replace('/size', '');
    return imagePath + '/' + name;
  } catch (error) {}
};

export const getFileURL = (name) => {
  try {
    if (!name) return '';
    const { fullUploadURL, uploadDirectory } = getPaths();
    let imagePath = fullUploadURL + uploadDirectory.paths.files;
    return imagePath + '/' + name;
  } catch (error) {}
};

export const getVideoURL = (name) => {
  try {
    if (!name) return '';
    const { fullUploadURL, uploadDirectory } = getPaths();
    let imagePath = fullUploadURL + uploadDirectory.paths.videos;
    return imagePath + '/' + name;
  } catch (error) {}
};
