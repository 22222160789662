<template>
  <v-progress-linear
    :buffer-value="bufferValue"
    :value="progress"
    stream
    color="orange"
    v-if="show"
  ></v-progress-linear>
</template>

<script>
export default {
  props: ['show', 'bufferValue', 'progress'],
};
</script>

<style lang="sass" scoped></style>
