<template>
  <div
    :class="['base-editor', darkClass]"
    class="base-editor"
    :style="{ '--ck-editor__editable__min-heigth': contentMinHeight }"
  >
    <div class="document-editor__toolbar" :id="id">
      <UploadImage
        v-model="imageSrc"
        text="."
        :multiple="false"
        :onSelectCloseDialog="true"
        :btnProps="{
          hideText: true,
          absolute: true,
          fab: true,
          bottom: true,
          left: true,
          small: true,
        }"
      />
    </div>
    <div class="document-editor__editable-container pa-4">
      <ckeditor
        :editor="editor"
        v-model="ckEditorData"
        :config="editorConfig"
        @ready="onReady"
        @input="onChange"
      ></ckeditor>
    </div>
  </div>
</template>

<script>
import Editor from 'ckeditor5-custom-build/build/ckeditor';
import UploadImage from '@/components/UploadManager/UploadImage.vue';
import { getImageURL } from '@/helper/cmsPaths';

export const buildJSON = (data) =>
  JSON.stringify({ generated: 'ckeditor', data });

export const getDataFromJSON = (contentJson) => {
  try {
    const content = JSON.parse(contentJson);
    return content.data || '';
  } catch (error) {
    return `<p>${contentJson}</p>`;
  }
};

export default {
  components: { UploadImage },
  props: ['value', 'id', 'contentMinHeight'],
  data() {
    return {
      editor: Editor,
      ckEditorData: '',
      imageSrc: '',
      editorConfig: {
        toolbar: {
          shouldNotGroupWhenFull: true,
          // items: [
          //   'heading',
          //   '|',
          //   'bold',
          //   'italic',
          //   'underline',
          //   '|',
          //   'link',
          //   'blockQuote',
          //   'insertTable',
          //   // 'imageInsert',
          //   '|',
          //   'fontColor',
          //   'fontBackgroundColor',
          //   '|',
          //   'fontSize',
          //   'fontFamily',
          //   '|',
          //   'alignment',
          //   '|',
          //   'indent',
          //   'outdent',
          //   '|',
          //   'undo',
          //   'redo',
          //   '|',
          //   'bulletedList',
          //   'numberedList',
          //   '|',
          //   'pageBreak',
          //   'horizontalLine',
          // ],
        },
        alignment: { options: ['left', 'right', 'center', 'justify'] },
        mediaEmbed: { previewsInData: true },
      },
    };
  },
  computed: {
    style() {
      return `--ck-editor__editable__min-heigth: ${
        this.contentMinHeight || 'initial'
      };`;
    },
    darkClass() {
      return this.$store.state.theme.dark ? 'base-editor--dark' : '';
    },
  },
  mounted() {
    this.ckEditorData = this.showImageDetails(this.value);
  },
  watch: {
    imageSrc: {
      handler(val) {
        this.imageSrc = '';

        if (!val) return;

        this.appendImage(val);
      },
    },
  },
  methods: {
    onReady(editor) {
      const toolbarContainer = document.querySelector(
        `.document-editor__toolbar#${this.id}`,
      );
      toolbarContainer.appendChild(editor.ui.view.toolbar.element);
    },
    onChange(value) {
      const withoutImagesValue = this.hideImageDetails(value);

      this.$emit('input', withoutImagesValue);
    },
    getPathForImage() {
      const src = this.getImageSrc(' ', 'lg').trim();
      return src;
    },
    getImageSrc(name, size) {
      return getImageURL(name, size);
    },
    appendImage(imageSrc) {
      this.ckEditorData += this.showImageDetails(
        `<figure class="image" style="width:95%;"><img src="#path#${imageSrc}"/></figure>`,
      );
    },
    hideImageDetails(value) {
      if (!value) return;

      const src = this.getPathForImage();
      const withoutImages = value.replaceAll(src, '#path#');
      return withoutImages;
    },
    showImageDetails(value) {
      if (!value) return;

      const src = this.getPathForImage();
      const withImages = value.replaceAll('#path#', src);
      return withImages;
    },
  },
};
</script>

<style lang="scss">
.base-editor {
  // dark editor
  &--dark {
    & > div { // container
      background: #eee !important;
      color: #000 !important;
    }
  }

  .ck-editor__editable {
    min-height: 500px;
  }

  .document-editor {
    border: 1px solid var(--ck-color-base-border);
    border-radius: var(--ck-border-radius);

    /* Set vertical boundaries for the document editor. */
    max-height: 700px;

    /* This element is a flex container for easier rendering. */
    display: flex;
    flex-flow: column nowrap;
  }

  .document-editor__toolbar {
    position: relative;
    
    /* Make sure the toolbar container is always above the editable. */
    z-index: 1;

    /* Create the illusion of the toolbar floating over the editable. */
    box-shadow: 0 0 5px hsla(0, 0%, 0%, 0.2);

    /* Use the CKEditor CSS variables to keep the UI consistent. */
    border-bottom: 1px solid var(--ck-color-toolbar-border);

    > .v-btn {
      right: 0;
    } 
  }

  /* Adjust the look of the toolbar inside the container. */
  .document-editor__toolbar .ck-toolbar {
    border: 0;
    border-radius: 0;
  }

  /* Make the editable container look like the inside of a native word processor application. */
  .document-editor__editable-container {
    // this is container he
    max-height: 80vh;
    // this is container he
    padding: calc(2 * var(--ck-spacing-large));
    background: var(--ck-color-base-foreground);

    /* Make it possible to scroll the "page" of the edited content. */
    overflow-y: auto;
  }
  .document-editor__editable-container p {
    margin: 0;
  }

  .document-editor__editable-container .ck-editor__editable {
    /* Set the dimensions of the "page". */
    max-width: 28cm;
    min-height: var(--ck-editor__editable__min-heigth, 21cm);

    /* Keep the "page" off the boundaries of the container. */
    padding: 1cm 2cm 2cm;

    border: 1px hsl(0, 0%, 82.7%) solid;
    border-radius: var(--ck-border-radius);
    background: white;

    /* The "page" should cast a slight shadow (3D illusion). */
    box-shadow: 0 0 5px hsla(0, 0%, 0%, 0.1);

    /* Center the "page". */
    margin: 0 auto;
  }

  /* Set the default font for the "page" of the content. */
  .document-editor .ck-content,
  .document-editor .ck-heading-dropdown .ck-list .ck-button__label {
    font: 16px/1.6 'Helvetica Neue', Helvetica, Arial, sans-serif;
  }

  /* Adjust the headings dropdown to host some larger heading styles. */
  .document-editor .ck-heading-dropdown .ck-list .ck-button__label {
    line-height: calc(
      1.7 * var(--ck-line-height-base) * var(--ck-font-size-base)
    );
    min-width: 6em;
  }

  /* Scale down all heading previews because they are way too big to be presented in the UI.
    Preserve the relative scale, though. */
  .document-editor
    .ck-heading-dropdown
    .ck-list
    .ck-button:not(.ck-heading_paragraph)
    .ck-button__label {
    transform: scale(0.8);
    transform-origin: left;
  }

  /* Set the styles for "Heading 1". */
  .document-editor .ck-content h2,
  .document-editor .ck-heading-dropdown .ck-heading_heading1 .ck-button__label {
    font-size: 2.18em;
    font-weight: normal;
  }

  .document-editor .ck-content h2 {
    line-height: 1.37em;
    padding-top: 0.342em;
    margin-bottom: 0.142em;
  }

  /* Set the styles for "Heading 2". */
  .document-editor .ck-content h3,
  .document-editor .ck-heading-dropdown .ck-heading_heading2 .ck-button__label {
    font-size: 1.75em;
    font-weight: normal;
    color: hsl(203, 100%, 50%);
  }

  .document-editor
    .ck-heading-dropdown
    .ck-heading_heading2.ck-on
    .ck-button__label {
    color: var(--ck-color-list-button-on-text);
  }

  /* Set the styles for "Heading 2". */
  .document-editor .ck-content h3 {
    line-height: 1.86em;
    padding-top: 0.171em;
    margin-bottom: 0.357em;
  }

  /* Set the styles for "Heading 3". */
  .document-editor .ck-content h4,
  .document-editor .ck-heading-dropdown .ck-heading_heading3 .ck-button__label {
    font-size: 1.31em;
    font-weight: bold;
  }

  .document-editor .ck-content h4 {
    line-height: 1.24em;
    padding-top: 0.286em;
    margin-bottom: 0.952em;
  }

  /* Set the styles for "Paragraph". */
  .document-editor .ck-content p {
    font-size: 1em;
    line-height: 1.63em;
    padding-top: 0.5em;
    margin-bottom: 1.13em;
  }

  /* Make the block quoted text serif with some additional spacing. */
  .document-editor .ck-content blockquote {
    font-family: Georgia, serif;
    margin-left: calc(2 * var(--ck-spacing-large));
    margin-right: calc(2 * var(--ck-spacing-large));
  }
}
</style>
