<template>
  <v-card elevation="0" class="my-5 pa-5" outlined>
    <v-row class="mb-2">
      <v-col>
        <h1>{{ title }}</h1>
      </v-col>
    </v-row>

    <v-row v-for="gf in groupFields" :key="gf.id">
      <!-- group -->
      <FieldGroup
        v-if="gf.isGroupd"
        :groupFields="gf"
        @removeGroup="removeGroup(gf.id)"
        :selectedLanguage="selectedLanguage"
        :canRemoveGroup="canRemove"
      >
        <component
          slot-scope="{ field }"
          :is="field.data.component"
          :ref="field.data.id"
          :data="field.data"
          :selectedLanguage="selectedLanguage"
          :hideDeleteField="true"
          :render="render"
        />
      </FieldGroup>

      <!-- only field -->
      <v-col cols="12" v-else>
        <component
          :is="gf.field.data.component"
          :ref="gf.field.data.id"
          :data="gf.field.data"
          @remove="removeField"
          :selectedLanguage="selectedLanguage"
          :render="render"
        />
      </v-col>
    </v-row>

    <v-row class="justify-center" v-if="render && loadingAdd">
      <v-progress-circular indeterminate color="primary" />
    </v-row>

    <v-row v-if="!render">
      <v-col>
        <v-card elevation="0" outlined>
          <v-progress-linear :active="loadingAdd" indeterminate />
          <v-card-text>
            <!-- 
                ------------------------------------
                Normal Fields
                ------------------------------------
               -->
            <v-row v-if="!render">
              <v-col>
                <v-btn
                  color="primary"
                  class="ma-1"
                  @click="addField"
                  :disabled="loadingAdd"
                >
                  <v-icon dark> mdi-plus </v-icon>
                  اضافة حقل
                </v-btn>
                <v-btn
                  color="secondary lighten-2"
                  class="white--text ma-1"
                  @click="addField({ fieldType: 'string' })"
                  :disabled="loadingAdd"
                >
                  <v-icon dark> mdi-card-text-outline </v-icon>
                  اضافة نص
                </v-btn>
                <v-btn
                  color="secondary lighten-2"
                  class="white--text ma-1"
                  @click="addField({ fieldType: 'number' })"
                  :disabled="loadingAdd"
                >
                  <v-icon dark> mdi-counter </v-icon>
                  اضافة رقم
                </v-btn>
                <v-btn
                  color="secondary lighten-2"
                  class="white--text ma-1"
                  @click="addField({ fieldType: 'date' })"
                  :disabled="loadingAdd"
                >
                  <v-icon dark> mdi-clipboard-text-clock </v-icon>
                  اضافة تاريخ
                </v-btn>
                <v-btn
                  color="secondary lighten-2"
                  class="white--text ma-1"
                  @click="addField({ fieldType: 'image' })"
                  :disabled="loadingAdd"
                >
                  <v-icon dark> mdi-file-image </v-icon>
                  اضافة صورة
                </v-btn>
                <v-btn
                  color="secondary lighten-2"
                  class="white--text ma-1"
                  @click="addField({ fieldType: 'email' })"
                  :disabled="loadingAdd"
                >
                  <v-icon dark> mdi-gmail </v-icon>
                  اضافة ايميل
                </v-btn>
                <v-btn
                  color="secondary lighten-2"
                  class="white--text ma-1"
                  @click="addField({ fieldType: 'phone-number' })"
                  :disabled="loadingAdd"
                >
                  <v-icon dark> mdi-phone </v-icon>
                  اضافة رقم هاتف
                </v-btn>
                <v-btn
                  color="secondary lighten-2"
                  class="white--text ma-1"
                  @click="addField({ fieldType: 'unordered-list' })"
                  :disabled="loadingAdd"
                >
                  <v-icon dark> mdi-format-list-checkbox </v-icon>
                  اضافة قائمة
                </v-btn>
                <v-btn
                  color="secondary lighten-2"
                  class="white--text ma-1"
                  @click="addField({ fieldType: 'ordered-list' })"
                  :disabled="loadingAdd"
                >
                  <v-icon dark> mdi-format-list-numbered </v-icon>
                  اضافة قائمة مرقمة
                </v-btn>
                <v-btn
                  color="red darken-1"
                  class="white--text ma-1"
                  @click="addField({ fieldType: 'video' })"
                  :disabled="loadingAdd"
                >
                  <v-icon dark> mdi-play-circle-outline </v-icon>
                  اضافة فديو
                </v-btn>
                <v-btn
                  color="red darken-2"
                  class="white--text ma-1"
                  @click="addField({ fieldType: 'embed' })"
                  :disabled="loadingAdd"
                >
                  <v-icon dark> mdi-vector-link </v-icon>
                  اضافة تضمين
                </v-btn>
                <v-btn
                  color="red darken-3"
                  class="white--text ma-1"
                  @click="addField({ fieldType: 'youtube-id' })"
                  :disabled="loadingAdd"
                >
                  <v-icon dark> mdi-youtube </v-icon>
                  اضافة معرف اليوتيوب
                </v-btn>
              </v-col>
            </v-row>

            <!-- 
                ------------------------------------
                Custom Fields
                ------------------------------------
               -->
            <v-row v-if="!render">
              <v-col>
                <v-btn
                  color="secondary"
                  class="white--text ma-1"
                  @click="addEditor"
                  :disabled="loadingAdd"
                >
                  <v-icon dark> mdi-text-box-edit-outline </v-icon>
                  اضافة محرر
                </v-btn>

                <v-btn
                  color="secondary"
                  class="white--text ma-1"
                  @click="addMultiEditor"
                  :disabled="loadingAdd"
                >
                  <v-icon dark> mdi-text-box-edit-outline </v-icon>
                  اضافة محرر متعدد
                </v-btn>

                <v-btn
                  color="secondary"
                  class="white--text ma-1"
                  @click="addSlider"
                  :disabled="loadingAdd"
                >
                  <v-icon dark> mdi-text-box-edit-outline </v-icon>
                  اضافة سلايدر
                </v-btn>

                <v-btn
                  color="secondary"
                  class="white--text ma-1"
                  @click="addFile"
                  :disabled="loadingAdd"
                >
                  <v-icon dark> mdi-text-box-edit-outline </v-icon>
                  اضافة فايلات
                </v-btn>

                <v-btn
                  color="secondary"
                  class="white--text ma-1"
                  @click="addTable"
                  :disabled="loadingAdd"
                >
                  <v-icon dark> mdi-table </v-icon>
                  اضافة جدول
                </v-btn>
              </v-col>
            </v-row>

            <!-- 
                ------------------------------------
                SchemaTypes
                ------------------------------------
               -->
            <!-- <v-row v-if="withSchemaTypes && render">
                <v-col>
                  <SchemaTypes
                    :loadingAdd="loadingAdd"
                    :selectedLanguage="selectedLanguage"
                    @pushed="pushedSchema"
                  />
                </v-col>
              </v-row> -->
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import Field from '@/components/Fields/Field.vue';
import FieldEditor from '@/components/Fields/FieldEditor.vue';
import SchemaTypes from '@/components/SchemaType/SchemaTypes.vue';
import FieldGroup from './FieldGroup.vue';
import Slider from '@/components/Sliders/Slider.vue';
import MultiEditor from '@/components/MultiEditor/MultiEditor.vue';
import Files from '@/components/Files/Files.vue';
import Table from '@/components/Tables/Table.vue';

export default {
  props: {
    title: {
      type: String,
      defualt: '',
    },
    schema: {},
    selectedLanguage: {},
    withSchemaTypes: {
      type: Boolean,
      defualt: false,
    },
    render: {
      type: Boolean,
      defualt: false,
    },
    grouped: {
      type: Boolean,
      defualt: false,
    },
    canRemove: {
      type: Boolean,
      defualt: true,
    },
    // bus: {
    //   type: Object,
    // },
  },
  components: {
    Field,
    Editor: FieldEditor,
    SchemaTypes,
    FieldGroup,
    Slider,
    MultiEditor,
    Files,
    Table,
  },
  data() {
    return {
      fields: [],
      fieldIds: 0,
      dtlsData: [],
      loadingAdd: false,
      // contentTypeId: null,
    };
  },
  computed: {
    groupFields() {
      const groups = this.buildGroupFields(this.fields);
      return groups;
    },
  },
  methods: {
    // ----------------------
    // Utls Methods
    // ----------------------
    buildGroupFields(xs) {
      return xs.reduce(function (groups, field) {
        const { id: fieldId, schemaName, groupId } = field.data;
        if (groupId) {
          // this is list of fields
          const group = groups.find((x) => x.id == groupId);
          if (group) group.values.push(field);
          else {
            const isGroupd = true;
            const id = groupId;
            const initValues = [field];
            groups.push({ id, schemaName, isGroupd, values: initValues });
          }
        } else {
          // this is single field
          groups.push({ id: fieldId, field });
        }
        return groups;
      }, []);
    },
    getFieldstDetails() {
      return Object.keys(this.$refs).map((fieldRefId) => {
        const fieldRef = this.$refs[fieldRefId];
        if (!fieldRef || !fieldRef.length) return {};
        const refDtls = fieldRef[0];
        return { data: refDtls._data, $v: refDtls.$v, ref: refDtls };
      });
    },
    fieldDtls(data) {
      return data?.field;
    },
    sliderDtls(data, ref) {
      const sliderImagesRefs = ref.getSliderImageDetails();

      const { field } = data || {};
      if (!field) return;

      const sliderImage = sliderImagesRefs
        .map((image) => {
          const { data, $v } = image;
          if (!data) return;
          return data?.field;
        })
        .filter((x) => !!x);

      const sliderField = { ...field, images: sliderImage };
      return sliderField;
    },
    filesDtls(data, ref) {
      const dtlsRefs = ref.getDetails();

      const { field } = data || {};
      if (!field) return;

      const dtls = dtlsRefs
        .map((ref) => {
          const { data, $v } = ref;
          if (!data) return;
          return data?.field;
        })
        .filter((x) => !!x);

      const fieldDtls = { ...field, files: dtls };
      return fieldDtls;
    },
    tableDtls(data, ref) {
      const dtlsRefs = ref.getDetails();

      const fieldDtls = { ...dtlsRefs };
      return fieldDtls;
    },
    multiEditorDtls(data, ref) {
      const dtlsRefs = ref.getDetails();

      const { field } = data || {};
      if (!field) return;

      const dtls = dtlsRefs
        .map((ref) => {
          const { data, $v } = ref;
          if (!data) return;
          return data?.field;
        })
        .filter((x) => !!x);

      const fieldDtls = { ...field, contents: dtls };
      return fieldDtls;
    },
    getFieldsData() {
      const details = this.dtlsData.map(({ data, ref }) => {
        const { field } = data || {};
        if (!field) return;
        if (field.component == 'Slider') return this.sliderDtls(data, ref);
        else if (field.component == 'Files') return this.filesDtls(data, ref);
        else if (field.component == 'MultiEditor')
          return this.multiEditorDtls(data, ref);
        else if (field.component == 'Table') return this.tableDtls(data, ref);
        else return this.fieldDtls(data);
      });
      return details.filter((x) => !!x);
    },
    chkField($v) {
      if (!$v) return false;
      $v.$touch();
      return $v.$invalid;
    },
    chkSlider(data, ref) {
      const sliderImagesRefs = ref.getSliderImageDetails();
      const validSliderImage = sliderImagesRefs.map((image) => {
        const { $v } = image;
        if (!$v) return false;
        $v.$touch();
        return $v.$invalid;
      });
      return validSliderImage.some((x) => x);
    },
    chkFiles(data, ref) {
      const dtlsRefs = ref.getDetails();
      const validDtls = dtlsRefs.map((ref) => {
        const { $v } = ref;
        if (!$v) return false;
        $v.$touch();
        return $v.$invalid;
      });
      return validDtls.some((x) => x);
    },
    chkIsAllFieldsInvalid() {
      const validResult = this.dtlsData.map(({ data, ref, $v }) => {
        const { field } = data || {};
        if (!field) return;
        if (field.component == 'Slider') return this.chkSlider(data, ref);
        else if (field.component == 'Files') return this.chkFiles(data, ref);
        else return this.chkField($v);
      });
      return validResult.some((x) => x);
    },
    scrollDownToLastField() {
      // const container = document.querySelector('#dialog .main-container__body');
      // container.scrollTo = ..;
      const theLastFields = this.$refs[this.fieldIds];
      theLastFields[0].$el.scrollIntoView();
    },
    getFieldsFromSchema(schema, newId = false) {
      try {
        if (!schema || schema.length == 0) return [];

        const fieldsData = JSON.parse(schema);
        if (fieldsData.length == 0) return [];

        const fields = fieldsData.map((fieldData) => ({
          data: {
            ...fieldData,
            id: newId ? this.generatedId() : fieldData.id,
            component: fieldData.component || 'Field',
          },
        }));

        return fields;
      } catch (error) {
        return [];
      }
    },
    initCards(schema) {
      if (!schema || schema.length == 0) {
        if (this.fields.length > 0) this.initFields([]);
        return;
      }
      const fields = this.getFieldsFromSchema(schema);

      // if (fields.length > 0 && this.render) {
      //   const { data } = fields.find((x) => !!x.data.contentTypeId) || {};
      //   const { contentTypeId } = data || {};
      //   this.contentTypeId = contentTypeId || null;
      // }

      this.initFields(fields);
    },
    initFields(fields) {
      this.withLoading(() => {
        if (fields.length != 0) {
          const maxId = Math.max(...fields.map((f) => f.data.id));
          this.fieldIds = maxId;
        } else {
          this.fieldIds = 0;
        }

        // add GroupId
        if (this.grouped && fields.length > 0) {
          this.fields = this.wrapWithGroup(fields);
        } else this.fields = fields;
      });
    },
    addContentTypeFields(fields, { contentTypeId, schemaName }) {
      this.withLoading(() => {
        const newFields = this.wrapWithGroup(fields, {
          contentTypeId,
          schemaName,
        });
        this.fields = [...this.fields, ...newFields];
      });
    },
    wrapWithGroup(fields, extraData = {}) {
      const newGroupId = this.generatedId();
      const newFields = fields.map((f) => ({
        ...f,
        data: {
          ...f.data,
          id: f.data.id || this.generatedId(),
          groupId: f.data.groupId || newGroupId,
          ...extraData,
        },
      }));
      return newFields;
    },
    onSubmitHandler() {
      // this.bus.$on('submit', () => {});
    },
    getData() {
      this.dtlsData = this.getFieldstDetails();

      const fields = this.getFieldsData();
      const invalid = this.chkIsAllFieldsInvalid();

      const schema = JSON.stringify(fields);
      // const contentTypeId = this.contentTypeId;

      return { fields, invalid, schema /*, contentTypeId*/ };
    },
    clear() {
      this.initCards('');
    },
    reset() {
      this.clear();
      this.initCards(this.schema);
    },
    // ----------------------
    // Add Remove Fields
    // ----------------------
    generatedId() {
      return ++this.fieldIds;
    },
    addField({ fieldType = '' }) {
      this.withLoading(() => {
        const data = {
          id: this.generatedId(),
          type: fieldType,
          component: 'Field',
        };
        this.fields.push({ data });
        setTimeout(() => this.scrollDownToLastField(), 100);
      });
    },
    addEditor() {
      this.withLoading(() => {
        const data = { id: this.generatedId(), component: 'Editor' };
        this.fields.push({ data });
        setTimeout(() => this.scrollDownToLastField(), 100);
      });
    },
    addSlider() {
      this.withLoading(() => {
        const data = { id: this.generatedId(), component: 'Slider' };
        this.fields.push({ data });
        setTimeout(() => this.scrollDownToLastField(), 100);
      });
    },
    withLoading(cb) {
      this.loadingAdd = true;
      setTimeout(() => {
        this.loadingAdd = false;
        cb();
      }, 500);
    },
    removeField(id) {
      const index = this.fields.findIndex((f) => f.data.id === id);
      index != -1 && this.fields.splice(index, 1);
    },
    removeGroup(groupId) {
      this.fields
        .filter((f) => f.data.groupId == groupId)
        .forEach((f) => {
          this.removeField(f.data.id);
        });
    },
    pushedSchema(type) {
      const {
        id: contentTypeId,
        isValid,
        schema,
        language: { name: schemaName },
      } = type;

      if (!isValid) return;
      const fields = this.getFieldsFromSchema(schema, true);
      this.addContentTypeFields(fields, { contentTypeId, schemaName });

      // if (!this.contentTypeId) this.setContentType(contentTypeId);
    },
    addMultiEditor() {
      this.withLoading(() => {
        const data = { id: this.generatedId(), component: 'MultiEditor' };
        this.fields.push({ data });
        setTimeout(() => this.scrollDownToLastField(), 100);
      });
    },
    addFile() {
      this.withLoading(() => {
        const data = { id: this.generatedId(), component: 'Files' };
        this.fields.push({ data });
        setTimeout(() => this.scrollDownToLastField(), 100);
      });
    },
    addTable() {
      this.withLoading(() => {
        const data = { id: this.generatedId(), component: 'Table' };
        this.fields.push({ data });
        setTimeout(() => this.scrollDownToLastField(), 100);
      });
    },
    // setContentType(typeId) {
    //   if (!this.render) return;
    //   this.contentTypeId = typeId;
    // },
  },
  mounted() {
    // this.onSubmitHandler();
  },
  created() {
    this.initCards(this.schema);
  },
  updated() {},
  watch: {
    schema(value) {
      this.initCards(value);
    },
    fields(value) {
      // if (value.length == 0) this.setContentType(null);
      if (value.length == 0) this.$emit('removeSchema');
    },
    loadingAdd(value) {
      this.$emit('loadingAdd', value);
    },
  },
};
</script>

<style lang="scss" scoped></style>
