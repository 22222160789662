<template>
  <v-card elevation="0" outlined :style="!render && cardStyle">
    <v-row :class="render ? 'ma-1' : 'ma-5'">
      <v-col cols="12">
        <v-btn
          fab
          absolute
          top
          left
          small
          color="pink"
          class="mr-1 white--text"
          :loading="cardStyle.borderColor == 'red'"
          @click="removeField"
          v-if="canDeleteField"
        >
          <v-icon color="#fff">mdi-delete</v-icon>
        </v-btn>

        <div class="mt-4" v-if="!render">
          <v-text-field v-model="field.slug" label="slug" outlined dense />
        </div>

        <FieldEditorLanguages
          v-model="field"
          :selectedLanguage="selectedLanguage"
          :render="render"
        />
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { makeid } from '@/utls/jsCommon';
import FieldEditorLanguages from './FieldEditorLanguages.vue';

export default {
  props: ['data', 'selectedLanguage', 'hideDeleteField', 'render'],
  components: {
    FieldEditorLanguages,
  },
  data() {
    return {
      field: { slug: '' },
      cardStyle: {
        border: '2px solid',
        borderColor: 'var(--primary-color)',
      },
    };
  },
  computed: {
    language() {},
    canDeleteField() {
      if (this.hideDeleteField == true) return false;
      return true;
    },
  },
  methods: {
    removeField() {
      this.cardStyle.borderColor = 'red';
      setTimeout(() => this.$emit('remove', this.data.id), 600);
    },
    initField() {
      if (this.data) this.field = this.data;
      if (!this.field.slug) this.field.slug = makeid(15);
    },
  },
  created() {
    this.initField();
  },
};
</script>

<style lang="scss" scoped></style>
