<template>
  <v-card elevation="0" outlined v-if="!buildSchemaDirect">
    <v-progress-linear :active="loadingForAdd" indeterminate />
    <v-card-title>نوع المحتوى</v-card-title>
    <v-card-text>
      <v-skeleton-loader
        class="ma-0 pa-0"
        lg1
        v-if="loadingData"
        type="actions"
      />
      <div v-else>
        <v-btn
          v-for="(type, index) in contentTypes"
          :key="type.id"
          :style="getBorderStyle(type)"
          :color="getBtnColor(type)"
          :disabled="loadingForAdd || loadingData || !type.isValid"
          class="white--text ma-1"
          @click="pushSchema(type)"
        >
          <v-icon dark> mdi-newspaper-variant </v-icon>
          {{ type.slug }}
          <div
            :style="
              loadingForAdd || loadingData || !type.isValid
                ? ''
                : 'color: #f0ffda'
            "
          >
            {{ '|' + type.language.name }}
          </div>
        </v-btn>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import {
  showSnackbarFailMessage,
  showSnackbarWarningMessage,
} from '@/utls/snackbar';
export default {
  props: {
    selectedLanguage: {
      type: String,
      required: true,
    },
    loadingAdd: {
      type: Boolean,
      default: false,
    },
    type: {},
    buildSchemaTypeId: {},
    pushDirect: {},
  },
  data() {
    return {
      loadingData: false,
      contentTypes: [],
    };
  },
  computed: {
    loadingForAdd() {
      return this.loadingAdd;
    },
    buildSchemaDirect() {
      return !!this.buildSchemaTypeId;
    },
  },
  methods: {
    getBtnColor(type) {
      if (this.type == type.id) return 'green darken-1';
      return 'green darken-4';
    },
    getBorderStyle(type) {
      if (!type.isValid) return { borderBottom: '2px solid red' };
      if (this.type == type.id) {
        return {
          borderBottom: '4px solid green !important',
        };
      }
    },
    toggleLoading() {
      this.loadingData = !this.loadingData;
    },
    checkSchemaIsValid(schema) {
      try {
        JSON.parse(schema);
        return true;
      } catch (error) {
        return false;
      }
    },
    pushSchema(type) {
      if (!type.isValid) {
        showSnackbarWarningMessage('يرجى تعديل الهيكل لايمكن اضافته');
        return;
      }
      this.$emit('pushed', type);
    },
    getData() {
      this.toggleLoading();
      this.$http
        .get('app/content-type/by-filters', {
          params: {
            language: this.selectedLanguage,
            id: this.buildSchemaTypeId,
            maxResultCount: 1000
          },
        })
        .then((response) => {
          const { items } = response.data;
          const resultData = items.reduce((p, c) => {
            const language = c.contentTypeLanguages[0] || {};
            const { id, schema, slug } = c;
            const isValid = this.checkSchemaIsValid(schema);
            const data = [...p, { id, schema, slug, language, isValid }];
            return data;
          }, []);
          this.contentTypes = resultData;
          if (this.buildSchemaDirect) this.buildCurrentSchema();
        })
        .catch((error) => {
          showSnackbarFailMessage('حدث خطأ اثناء عملية جلب انواع المحتوى');
        })
        .finally(() => this.toggleLoading());
    },
    buildCurrentSchema() {
      if (!this.buildSchemaDirect) return;
      if (!this.pushDirect) return;
      const type = this.contentTypes[0];
      if (type) this.pushSchema(type);
    },
  },
  created() {
    this.getData();
  },
};
</script>

<style lang="scss" scoped></style>
