<template>
  <div :style="sliderStyle">
    <v-btn
      fab
      absolute
      top
      left
      small
      color="pink"
      class="mr-1 white--text"
      :loading="sliderStyle.borderColor == 'red'"
      @click="removeSlider(id)"
      v-if="canDeleteField"
    >
      <v-icon color="#fff">mdi-delete</v-icon>
    </v-btn>
    <div class="my-5 px-5">
      <h2>سلايدر</h2>
    </div>

    <!-- Create -->
    <template>
      <v-row>
        <v-col cols="12">
          <hr style="width: 120px" />
        </v-col>
        <v-col cols="12" v-if="!render">
          <SliderLanguages
            v-model="field"
            :selectedLanguage="selectedLanguage"
            :render="render"
          />
        </v-col>

        <v-col cols="12" v-if="!render">
          <v-text-field v-model="field.slug" label="slug" outlined />
        </v-col>
      </v-row>
    </template>

    <!-- Redner -->
    <template v-if="render">
      <v-row v-if="sliderImages.length">
        <v-col cols="12">
          <v-carousel v-model="sliderModel">
            <v-carousel-item
              class="fill-width"
              v-for="slider in sliderImages"
              :key="slider.data.id"
            >
              <!-- SliderImage -->
              <component
                :is="slider.data.component"
                :data="slider.data"
                :ref="slider.data.id"
                @remove="removeSliderImage"
                :selectedLanguage="selectedLanguage"
                :hideDeleteField="!canDeleteSliderImage"
                :render="render"
              />
            </v-carousel-item>
          </v-carousel>
        </v-col>
      </v-row>

      <v-row v-if="canAddSliderImage">
        <v-col>
          <v-btn
            color="primary"
            @click="addSliderImage"
            :loading="loadingAdd"
            :disabled="loading"
          >
            <v-icon dark> mdi-plus </v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </template>
  </div>
</template>

<script>
import { makeid } from '@/utls/jsCommon';
import SliderImage from './SliderImage.vue';
import SliderLanguages from './SliderLanguages.vue';

export default {
  props: {
    selectedLanguage: {},
    hideDeleteField: {},
    data: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    render: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    SliderImage,
    SliderLanguages,
  },
  data() {
    return {
      sliderModel: 0,
      imageIds: 0,
      sliderImages: [],
      loadingAdd: false,
      sliderStyle: {
        border: '2px solid',
        borderColor: 'var(--secondary-color)',
        borderRadius: '4px',
        padding: '10px 20px 30px',
        marginBottom: '10px',
        width: '100%',
        position: 'relative',
      },
      field: {
        slug: '',
        name: {},
        images: [
          {
            src: '',
            order: 1,
            description: {}, // multi languages { ar: value, en: value }
          },
        ], // multi languages { ar: value, en: value }
      },
    };
  },
  computed: {
    id() {
      const { id } = this.data;
      return id;
    },
    canDeleteField() {
      if (this.hideDeleteField == true) return false;
      return true;
    },
    canAddSliderImage() {
      return true;
    },
    canDeleteSliderImage() {
      return true;
    },
  },
  methods: {
    getSliderImageDetails() {
      return Object.keys(this.$refs).map((fieldRefId) => {
        const fieldRef = this.$refs[fieldRefId];
        if (!fieldRef || !fieldRef.length) return {};
        const refDtls = fieldRef[0];
        return { data: refDtls._data, $v: refDtls.$v };
      });
    },
    removeSlider(id) {
      this.sliderStyle.borderColor = 'red';
      setTimeout(() => this.$emit('remove', id), 600);
    },
    removeSliderImage(id) {
      const index = this.sliderImages.findIndex((f) => f.data.id === id);
      index != -1 && this.sliderImages.splice(index, 1);
    },
    withLoading(cb) {
      this.loadingAdd = true;
      setTimeout(() => {
        this.loadingAdd = false;
        cb();
      }, 500);
    },
    addSliderImage(loading = true) {
      const add = () => {
        const data = {
          id: this.generatedId(),
          order: this.generatedOrder(),
          component: 'SliderImage',
        };
        this.sliderImages.push({ data });

        this.changeSliderToCurrentAddedImage();
      };
      if (loading) this.withLoading(add);
      else add();
    },
    changeSliderToCurrentAddedImage() {
      const lengthOfImages = this.sliderImages.length;
      if (lengthOfImages == 0) this.sliderModel = lengthOfImages;
      else this.sliderModel = lengthOfImages - 1;
    },
    generatedId() {
      return ++this.imageIds;
    },
    generatedOrder() {
      const images = this.sliderImages || [];
      if (images.length == 0) return 1;
      const nextOrder = Math.max(...images.map((o) => o.data.order)) + 1;
      return nextOrder;
    },
    initSliderImage({ empty, images }) {
      if (empty) this.addSliderImage(false);
      if (!images || images.length == 0) return;
      this.sliderImages = images.map((image) => ({ data: { ...image } }));
      const maxId = Math.max(...images.map((f) => f.id));
      this.imageIds = maxId;
    },
    initField() {
      if (this.data) this.field = this.data;

      const { images } = this.data || {};
      if (images) this.initSliderImage({ images });
      // else this.initSliderImage({ empty: false });

      if (!this.field.slug) this.field.slug = makeid(6);
    },
    changeCardBorderColor() {
      const isDark = this.$store.state.theme.dark;

      let bcolor = 'var(--primary-color)';
      if (this.render && isDark) bcolor = '#333';
      else if (this.render && !isDark) bcolor = '#eee';

      this.sliderStyle.borderColor = bcolor;
    },
  },
  created() {
    this.initField();
  },
  mounted() {
    this.changeCardBorderColor();
  },
};
</script>

<style lang="scss" scoped></style>
