export const inputTypes = [
  'string',
  'long-string',
  'boolean',
  'number',
  'date',
  'image',
  'phone-number',
  'email',
  'ordered-list',
  'unordered-list',
  'video',
  'embed',
  'youtube-id',
  // 'enum',
  // 'multiple-enum',
  // 'json',
  // 'json-multiple',
  // 'lookup',
];

export const typeHelper = {
  isString(type) {
    return ['string'].indexOf(type) != -1;
  },
  isLongString(type) {
    return ['long-string'].indexOf(type) != -1;
  },
  isBoolean(type) {
    return ['boolean'].indexOf(type) != -1;
  },
  isNumber(type) {
    return ['number'].indexOf(type) != -1;
  },
  isDate(type) {
    return ['date'].indexOf(type) != -1;
  },
  isImage(type) {
    return ['image'].indexOf(type) != -1;
  },
  isPhoneNumber(type) {
    return ['phone-number'].indexOf(type) != -1;
  },
  isEmail(type) {
    return ['email'].indexOf(type) != -1;
  },
  isOrderedList(type) {
    return ['ordered-list'].indexOf(type) != -1;
  },
  isUnorderedList(type) {
    return ['unordered-list'].indexOf(type) != -1;
  },
  isVideo(type) {
    return ['video'].indexOf(type) != -1;
  },
  isEmbed(type) {
    return ['embed'].indexOf(type) != -1;
  },
  isYoutubeId(type) {
    return ['youtube-id'].indexOf(type) != -1;
  },
};
