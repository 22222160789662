<template>
  <v-dialog
    v-if="isCanRead"
    v-model="dialog"
    transition="dialog-bottom-transition"
    width="85%"
    :persistent="isLoadingUpload || isLoadingDelete"
    :fullscreen="isFullscreen"
    class="my-custom-dialog"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        color="primary"
        dark
        v-bind="{...attrs, ...btnProps}"
        v-on="on"
      >
        <v-icon>{{ btnIcon }}</v-icon>
        {{ btnText }}
      </v-btn>
    </template>

    <v-card class="upload-manager-container">
      <!-- Header -->
      <v-toolbar dark color="secondar" class="flex-grow-0">
        <v-btn
          icon
          dark
          @click="dialog = false"
          :disabled="isLoadingUpload || isLoadingDelete"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>

        <v-btn icon dark @click="toggleFullscreen()" class="ml-5">
          <v-icon v-if="isFullscreen">mdi-fullscreen-exit</v-icon>
          <v-icon v-else>mdi-fullscreen</v-icon>
        </v-btn>

        <v-tabs
          align-with-title
          class="flex-grow-0"
          v-model="tabs"
          color="secondar"
        >
          <v-tab>تحميل</v-tab>
          <v-tab :disabled="isEdit">المكتبة</v-tab>
        </v-tabs>

        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          v-if="tabs == 1"
          label="بحث"
          single-line
          hide-details
          v-on:keyup.enter="
            () => {
              pageNumber = 1;
              files = [];
              searchUsed = true;
              getFiles(pageNumber);
            }
          "
        >
          <v-icon
            v-if="search"
            slot="append"
            @click="
              () => {
                search = '';
                if (searchUsed && search == '') {
                  pageNumber = 1;
                  files = [];
                  searchUsed = false;
                  getFiles(pageNumber);
                }
              }
            "
            >mdi-close</v-icon
          >
          <v-icon v-if="!search" slot="append">mdi-magnify</v-icon>
        </v-text-field>
        <div style="width: 100px" class="d-flex justify-end align-center">
          <v-toolbar-title v-if="!isLoadingUpload" class="ml-5">{{
            title
          }}</v-toolbar-title>

          <v-progress-circular
            v-if="isLoadingUpload || isLoadingDelete"
            indeterminate
          />
        </div>
      </v-toolbar>

      <v-card
        class="w-100 overflow-auto flex-grow-1 d-flex flex-column"
        color="deep-purple accent-4"
      >
        <v-tabs-items v-model="tabs" class="flex-grow-1 d-flex flex-column">
          <v-tab-item class="flex-grow-1">
            <!-- ----------------------------------------------------------------- -->
            <!-- uploadFile  -->
            <!-- ----------------------------------------------------------------- -->
            <template v-if="isCanCreate">
              <upload-progress-linear
                :show="isLoadingUpload"
                :bufferValue="uploadProgress + uploadProgress / 10"
                :progress="uploadProgress"
              />
              <v-container v-if="fileSrc" fluid>
                <v-row style="overflow: auto">
                  <v-col>
                    <v-container style="position: relative" fluid>
                      <v-row>
                        <v-col cols="12">
                          <v-row style="display: none">
                            <v-col>
                              <v-file-input
                                ref="fileRef"
                                :disabled="isLoadingUpload"
                                hide-input
                                v-model="file"
                                counter
                                :accept="accept"
                                prepend-icon="mdi-camera"
                                class="ma-0 pa-0"
                              >
                              </v-file-input>
                            </v-col>
                          </v-row>

                          <v-row>
                            <!-- upload buttons and informations -->
                            <v-col class="py-0">
                              <!-- alt text-field   -->
                              <v-row class="justify-center">
                                <v-col cols="12">
                                  <v-text-field
                                    v-model="alt"
                                    :label="fileNameLabel || 'اسم الملف'"
                                    outlined
                                    flat
                                    hide-details
                                    :error-messages="altErrors"
                                    @input="$v.alt.$touch()"
                                    @blur="$v.alt.$touch()"
                                    :disabled="isLoadingUpload"
                                  />
                                </v-col>
                              </v-row>

                              <!-- btns   -->
                              <v-row v-if="uploadType !== 'image'">
                                <v-col>
                                  <v-btn
                                    color="primary"
                                    block
                                    :disabled="isLoadingUpload"
                                    @click="uploadFile"
                                    class="my-1"
                                    large
                                  >
                                    <v-icon v-if="fileId"
                                      >mdi-content-save</v-icon
                                    >
                                    <v-icon v-if="!fileId">mdi-upload</v-icon>
                                    {{ fileId ? 'حفظ' : 'رفع' }}
                                  </v-btn>

                                  <v-btn
                                    v-if="!fileId"
                                    color="primary"
                                    block
                                    :disabled="isLoadingUpload"
                                    @click="browseFile"
                                    class="my-1"
                                    large
                                  >
                                    <v-icon>{{ browseFileIcon }}</v-icon>
                                    اختر
                                  </v-btn>
                                  <v-btn
                                    v-if="fileId"
                                    color="primary"
                                    block
                                    :disabled="isLoadingUpload"
                                    @click="resetUpload"
                                    class="my-1"
                                    large
                                  >
                                    <v-icon>mdi-close</v-icon>
                                    الغاء
                                  </v-btn>
                                </v-col>
                              </v-row>
                            </v-col>
                            <!-- uploadfilePreview   -->
                            <v-col :cols="isReverse ? '12' : '6'" class="py-0">
                              <v-card>
                                <slot
                                  name="uploadfilePreview"
                                  :taggleEdit="taggleEdit"
                                  :resetUpload="resetUpload"
                                  :isLoadingFile="isLoadingFile"
                                  :getFileFromUrl="getFileFromUrl"
                                  :browseFile="browseFile"
                                  :fileSrc="fileSrc"
                                  :file="file"
                                  :fileId="fileId"
                                  :changeUploadFile="changeUploadFile"
                                  :uploadFile="uploadFile"
                                />
                              </v-card>
                            </v-col>
                          </v-row>
                          <v-row />
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-col>
                </v-row>
              </v-container>
              <v-card
                v-if="!fileSrc"
                @drop.prevent="onDrop($event)"
                @dragover.prevent="dragover = true"
                @dragenter.prevent="dragover = true"
                @dragleave.prevent="dragover = false"
                :class="{
                  'grey lighten-2': dragover,
                  'd-flex flex-column justify-center align-center fill-height': true,
                }"
              >
                <v-file-input
                  style="display: none"
                  ref="fileRef"
                  :disabled="isLoadingUpload"
                  hide-input
                  v-model="file"
                  counter
                  :accept="accept"
                  prepend-icon="mdi-camera"
                  class="ma-0 pa-0"
                >
                </v-file-input>
                <div class="d-flex flex-column justify-center align-center">
                  <v-card-title>اسحب الملف هنا لرفعه</v-card-title>
                  <p>أو</p>
                  <v-btn
                    color="secondary"
                    block
                    elevation="2"
                    x-large
                    :disabled="isLoadingUpload"
                    @click="browseFile"
                  >
                    <v-icon class="ml-1">{{ browseFileIcon }}</v-icon>
                    اختر
                  </v-btn>
                </div>
              </v-card>
            </template>
          </v-tab-item>

          <v-tab-item class="flex-grow-1 overflow-auto">
            <template>
              <v-card-text class="upload-manager-container__body">
                <!-- ----------------------------------------------------------------- -->
                <!-- filesGridPreview  -->
                <!-- ----------------------------------------------------------------- -->
                <v-row>
                  <slot
                    name="filesGridPreview"
                    :files="files"
                    :selectFile="selectFile"
                    :getSelectedFileClass="getSelectedFileClass"
                    :isSelected="isSelected"
                    :remove="remove"
                    :editFile="editFile"
                    :isLoading="isLoadingUpload || isLoadingDelete"
                  />
                  <v-col cols="12" class="text-center">
                    <v-btn
                      v-if="files.length < totalCount && !isLoadingUpload"
                      color="primary"
                      @click="getFiles(++pageNumber)"
                      :disabled="isLoadingUpload || isLoadingDelete"
                    >
                      <v-icon class="ml-1">mdi-arrow-down</v-icon>
                      تحميل المزيد
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
            </template>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </v-card>
  </v-dialog>
</template>

<script>
import {
  showSnackbarFailMessage,
  showSnackbarSuccessMessage,
  showSnackbarWarningMessage,
} from '@/utls/snackbar';
import axiosIns from '@/libs/http/http';

import { validationMixin } from 'vuelidate';
import { required, maxLength, minLength } from 'vuelidate/lib/validators';
import PermissionMixin from '@/mixins/PermissionMixin';
import { appPermissions } from '@/libs/auth/permissions/appPermissions';
import UploadProgressLinear from './components/UploadProgressLinear.vue';
import { getCurrentDate } from '@/helper/commonjs';

import { mapActions } from 'vuex';
import { VContainer } from 'vuetify/lib';
import { VBtn } from 'vuetify/lib';
import {
  getFileNameWithoutExtension,
  getBlobTypeFromExtension,
} from '@/utls/utils';

export default {
  components: { UploadProgressLinear, VContainer, VBtn },
  props: [
    'value',
    'selectedLajnguage',
    'buttonTitle',
    'buttonIcon',
    'multiple',
    'uploadNotes',
    'uploadType',
    'accept',
    'browseFileIcon',
    'title',
    'gridTitle',
    'uploadTitle',
    'selectFileToUploadMsg',
    'successUploadMsg',
    'fileNameLabel',
    'onSelectCloseDialog',
    'btnProps',
  ],
  mixins: [validationMixin, PermissionMixin(appPermissions.upload)],
  validations() {
    return {
      alt: {
        required,
        maxLength: maxLength(40),
      },
    };
  },
  data() {
    return {
      amended: false,
      tabs: null,
      pageNumber: 1,
      maxResultCount: 15,
      totalCount: 0,
      searchUsed: false,
      dialog: false,
      dragover: false,
      isFullscreen: false,
      files: [],
      selectedFiles: [],
      file: null,
      fileSrc: null,
      fileId: null,
      isLoadingUpload: false,
      isLoadingDelete: false,
      isLoadingFile: false,
      uploadProgress: 0.5,
      alt: '',
      extension: '',
      isEdit: false,
      // uploadSheet: false,
      showUploudSheet: false,
      search: '',
      // sevedEdit: false,
    };
  },
  computed: {
    isReverse() {
      return this.uploadType == 'image';
    },
    altErrors() {
      const errors = [];
      if (!this.$v.alt.$dirty) return errors;
      !this.$v.alt.maxLength && errors.push('لايمكن تجاوز الحد المسموح');
      !this.$v.alt.required && errors.push('الاسم مطلوب');
      return errors;
    },
    isMultiSelect() {
      if (this.multiple == undefined) return true;
      return this.multiple;
    },
    btnText() {
      if (this.btnProps?.hideText) return '';
      return this.buttonTitle || 'اضافة/الغاء ملف';
    },
    btnIcon() {
      return this.buttonIcon || 'mdi-attachment';
    },
  },
  methods: {
    ...mapActions('confirm', ['openConfirm']),
    changeUploadFile(file) {
      this.fileId = null;
      this.file = file;
      this.dragover = false;
    },
    onDrop(e) {
      // check if file is accepted type
      if (!this.accept.includes(e.dataTransfer.files[0].type)) {
        showSnackbarWarningMessage('نوع الملف غير مسموح');
        return;
      }
      this.file = e.dataTransfer.files[0];
    },
    generateRandomNumber(minimum, maximum) {
      const minMax = maximum - minimum + 1;
      var randomnumber = Math.floor(Math.random() * minMax) + minimum;
      return randomnumber;
    },
    getFiles(pageNumber) {
      if (!this.isCanRead) return;
      // Calculate the SkipCount based on the page number
      const skipCount = (pageNumber - 1) * this.maxResultCount;
      // Items have already been requested
      if (this.isLoadingUpload) return;
      else this.isLoadingUpload = true;

      // Lazily load input items
      axiosIns
        .get('app/upload/upload-manager', {
          params: {
            language: 'all',
            type: this.uploadType,
            Alt: this.search,
            SkipCount: skipCount,
            maxResultCount: this.maxResultCount,
          },
        })
        .then((response) => {
          const responseDataItems = response.data.items;
          this.totalCount = response.data.totalCount;
          const newFiles = responseDataItems.map((x) => ({
            id: x.id ?? 0,
            alt: x.alt || '',
            src: x.name + x.extension,
            extension: x.extension,
            creator: x.creator,
          }));
          this.files = [...this.files, ...newFiles];
        })
        .catch((error) => console.log(error))
        .finally(() => (this.isLoadingUpload = false));
    },

    toggleFullscreen() {
      this.isFullscreen = !this.isFullscreen;
    },
    selectFile(fileSrc) {
      if (!this.isCanUpdate) {
        showSnackbarWarningMessage('لاتوجد صلاحية التغيير !');
        return;
      }
      const isSelected = this.isSelected(fileSrc);
      if (isSelected) {
        // delete
        this.removeSelectedFile(fileSrc);
      } else {
        // add
        if (this.isMultiSelect) {
          this.selectedFiles.push(fileSrc);
        } else {
          this.selectedFiles = [fileSrc];
        }
      }
    },
    removeSelectedFile(src) {
      const index = this.selectedFiles.indexOf(src);
      this.selectedFiles.splice(index, 1);
    },
    isSelected(fileSrc) {
      const index = this.selectedFiles.indexOf(fileSrc);
      return index != -1;
    },
    remove(fileId) {
      this.openConfirm({
        message: 'هل انت متأكد من الحذف ؟',
        confirm: () => this.doRemove(fileId),
      });
    },
    doRemove(fileId) {
      if (!this.isCanDelete) {
        showSnackbarWarningMessage('لاتوجد صلاحية المسح !');
        return;
      }

      this.isLoadingDelete = true;

      return axiosIns
        .delete('app/upload/' + fileId)
        .then((response) => {
          // get file and index
          const file = this.files.find((x) => x.id == fileId);
          const index = this.files.findIndex((x) => x.id == file.id);

          // remove from selected files if is selected
          const isSelected = this.isSelected(file.src);
          if (isSelected) this.removeSelectedFile(file.src);

          // remove file from files
          this.files.splice(index, 1);

          return true;
        })
        .catch((error) => console.log(error))
        .finally(() => (this.isLoadingDelete = false));
    },
    getSelectedFileClass(fileSrc) {
      const isSelected = this.isSelected(fileSrc);
      if (isSelected) return 'file selected-file';
      else return 'file';
    },
    readFileAndChangePreview(input) {
      var reader = new FileReader();
      reader.onload = (e) => {
        const newSrc = e.target.result;
        this.changePreviewFile(newSrc);
        this.changeAlt(input.name);
      };
      reader.readAsDataURL(input);
    },
    changePreviewFile(src) {
      this.fileSrc = src;
    },
    changeAlt(name) {
      this.alt = getFileNameWithoutExtension(name);
    },
    async browseFile() {
      try {
        const { fileRef } = this.$refs;
        fileRef.$el.querySelector('input').click();
      } catch (error) {}
    },
    isInvalid() {
      if (!this.file && !this.fileId) {
        showSnackbarWarningMessage(this.selectFileToUploadMsg);
        return true; // invalid
      }
      this.$v.$touch();
      if (this.$v.$invalid) return true; // invalid
      return false;
    },
    editFile(id, alt, src, extension) {
      this.fileId = id;
      this.alt = alt;
      this.fileSrc = src;
      this.extension = extension;
      this.tabs = 0;
    },
    getFileFromUrl() {
      if (!this.fileId) return;
      this.isLoadingFile = true;
      axiosIns
        .get('download/' + this.fileId, { responseType: 'blob' })
        .then((response) => {
          const blob = response.data;
          const file = new File([blob], this.alt + this.extension, {
            type: getBlobTypeFromExtension(this.extension),
            lastModified: Date.now(),
          });
          this.file = file;
          this.isLoadingFile = false;
        })
        .catch((error) => console.log(error));
    },

    async uploadFile() {
      if (this.isInvalid()) return;
      this.isLoadingUpload = true;
      var uploadStatus = await this.postFile();
      if (uploadStatus.success) {
        showSnackbarSuccessMessage(this.successUploadMsg);
        // this.files.unshift(uploadStatus.file);
        if (this.isCanUpdate) {
          // change or add to selected files
          const { src } = uploadStatus.file;
          if (this.isMultiSelect) this.selectedFiles.push(src);
          else this.selectedFiles = [src];
        }
        this.files = [];
        this.resetUpload();
        this.getFiles(this.pageNumber);
        this.tabs = 1;
      } else {
        const { status, data } = uploadStatus.error || {};
        const { error: { message } = {} } = data || {};
        if (status == 403) showSnackbarFailMessage(message);
        else showSnackbarFailMessage('حدث خطأ أثناء عملية التحميل');
        this.isLoadingUpload = false;
        this.uploadProgress = 0.5;
      }
    },
    resetUpload() {
      this.isLoadingUpload = false;
      this.isLoadingDelete = false;
      this.uploadProgress = 0.5;
      this.totalCount = 0;
      this.file = null;
      this.fileSrc = null;
      this.alt = '';
      this.$v.$reset();
      // this.uploadSheet = false;
      this.fileId = null;
      this.tabs = 1;
    },
    async postFile() {
      try {
        const config = {
          onUploadProgress: (progressEvent) => {
            const progress = (progressEvent.loaded * 100) / progressEvent.total;
            this.uploadProgress = Math.round(progress);
          },
        };

        let uploadResult = null;

        if (this.fileId == null) {
          // ----------
          // create
          // ----------
          const formData = new FormData();
          formData.append('file', this.file);
          formData.append('notes', this.uploadNotes);
          formData.append('alt', this.alt);
          formData.append('type', this.uploadType);
          formData.append('language', this.selectedLajnguage || 'ar');

          config.headers = { 'Content-Type': 'multipart/form-data;' };

          const url = 'app/upload/upload-manager';
          uploadResult = await this.$http.post(url, formData, config);
        } else {
          // ----------
          // update
          // ----------
          const data = { alt: this.alt };
          const url = 'app/upload/' + this.fileId;
          uploadResult = await this.$http.put(url, data, config);

          // reset file id
          this.fileId = null;
        }

        const success = uploadResult.status == 200;
        if (!success) return { success, file: null };
        const { name, extension, alt, id } = uploadResult.data;

        const file = {
          id,
          alt,
          src: name + extension,
        };

        return { success, file };
      } catch (error) {
        return { error: error.response };
      }
    },

    initSelected(newValue) {
      if (this.isMultiSelect) {
        if (!Array.isArray(newValue)) return;
        this.selectedFiles = newValue;
      } else {
        this.selectedFiles = [newValue];
      }
    },
    taggleEdit() {
      this.isEdit = !this.isEdit;
    },
    // toggleUploadSheet() {
    //   this.uploadSheet = !this.uploadSheet;
    //   if (this.uploadSheet && !this.isLoadingUpload) {
    //     this.alt = '';
    //   }
    // },
  },
  watch: {
    file(newValue, oldValue) {
      if (newValue) this.readFileAndChangePreview(newValue);
      else this.changePreviewFile(null);
    },
    selectedFiles(newValue) {
      const postValue = this.isMultiSelect ? newValue : newValue[0] || '';
      this.$emit('input', postValue);
      if (this.onSelectCloseDialog && postValue) this.dialog = false;
    },
    value(newValue) {
      this.initSelected(newValue);
    },
    dialog(newValue) {
      if (!newValue) return (this.files = []);
      this.getFiles(this.pageNumber);
      this.initSelected(this.value);
    },
    search: {
      handler() {
        if (this.search == '' && this.searchUsed) {
          this.pageNumber = 1;
          this.files = [];
          this.searchUsed = false;
          this.getFiles(this.pageNumber);
        }
      },
    },
  },
};
</script>

<style lang="scss">
.upload-manager-container {
  height: 90vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  &__body {
    flex: 1;
    overflow: auto;
    scroll-behavior: smooth;

    .file {
      // cursor: pointer;
      &.selected-file {
        border: 4px solid var(--primary-color) !important;
      }
    }
  }

  &__actions {
    &.footer,
    &.selected-files {
      padding: 20px;
    }
  }
}

.v-window__container {
  height: 100%;
}

.v-tabs {
  width: auto;
}

.my-custom-dialog {
  position: relative;
}
</style>
